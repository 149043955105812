// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NC_wBVPeZ"];

const variantClassNames = {NC_wBVPeZ: "framer-v-16g0bld"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, j78Gmgbti: title ?? props.j78Gmgbti ?? "ErrorNotice"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, j78Gmgbti, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NC_wBVPeZ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-WuyCS", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-16g0bld", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"NC_wBVPeZ"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Flb25payBCb2xk", "--framer-font-family": "\"Aeonik Bold\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(181, 38, 38))"}}>ErrorNotice</motion.p></React.Fragment>} className={"framer-1lcnkvl"} data-framer-name={"Vamos enviar o link para este email"} fonts={["CUSTOM;Aeonik Bold"]} layoutDependency={layoutDependency} layoutId={"JEjDS_meZ"} style={{"--extracted-r6o4lv": "rgb(181, 38, 38)", "--framer-paragraph-spacing": "0px"}} text={j78Gmgbti} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-WuyCS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WuyCS .framer-1c0cxy { display: block; }", ".framer-WuyCS .framer-16g0bld { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-WuyCS .framer-1lcnkvl { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WuyCS .framer-16g0bld { gap: 0px; } .framer-WuyCS .framer-16g0bld > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WuyCS .framer-16g0bld > :first-child { margin-left: 0px; } .framer-WuyCS .framer-16g0bld > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 96
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"j78Gmgbti":"title"}
 * @framerImmutableVariables false
 */
const Framerz1r7nlNti: React.ComponentType<Props> = withCSS(Component, css, "framer-WuyCS") as typeof Component;
export default Framerz1r7nlNti;

Framerz1r7nlNti.displayName = "Login/ErrorMessage";

Framerz1r7nlNti.defaultProps = {height: 24, width: 96};

addPropertyControls(Framerz1r7nlNti, {j78Gmgbti: {defaultValue: "ErrorNotice", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerz1r7nlNti, [{family: "Aeonik Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/z1r7nlNti:default", url: "https://framerusercontent.com/assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf"}, url: "https://framerusercontent.com/assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf"}])